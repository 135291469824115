body.chakra-ui-light {
  background: var(--chakra-colors-gray-200) !important;
}
body.chakra-ui-dark {
  background: var(--chakra-colors-gray-600) !important;
}
body {
  overflow-y: scroll;
}

div.kickstarter-update {
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
}
div.kickstarter-update > p {
  margin-bottom: var(--chakra-space-4);
}
div.kickstarter-update figure {
  margin-bottom: var(--chakra-space-4);
}
div.kickstarter-update img,
div.kickstarter-update video {
  margin-left: auto;
  margin-right: auto;
}
